//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ScrollTransition } from '@fc/angie-ui';
import SplitView from '~/components/SplitView.vue';
import FCImage from '~/components/FCImage.vue';

export default {
  name: 'OriginStoryTabletUpView',
  components: {
    ScrollTransition,
    SplitView,
    FCImage,
  },
  props: {
    originStoryList: {
      type: Array,
      required: true,
      validator(data) {
        return data.every(entry => entry.src && entry.alt && entry.title && entry.text);
      },
    },
  },
  computed: {
    isTextReverseOrder() {
      return (index) => [{ textReverseOrder: !!index }, index % 2 ? 'origin-story-tablet-up-view__text-align-right' : 'origin-story-tablet-up-view__text-align-left'];
    },
    isReverseSplitViewOrder() {
      return (index) => !(index % 2 !== 0);
    },
  },
};
